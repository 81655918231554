import React from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { SvgIcon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    faqHeader: {
      marginBottom: 0,
      padding: "30px",
      background: "linear-gradient(to right, rgba(241,193,193,255) 0%, rgba(245,227,138,255) 100%)",
      fontFamily: "Horizon",
      textAlign: "center",
      ["@media (max-width:1024px)"]: {
        fontSize: "1.2em"
      }
    },
    questionAnswer: {
      padding: "10px",
      ["@media (max-width:1024px)"]: {
        padding: 0,
      }
    },
    peachAccordion: {
      background: "rgba(234,192,173,255)"
    },
    lightPinkAccordion: {
      background: "rgba(249,230,232,255)"
    },
    yellowAccordion: {
      background: "rgba(245,230,136,255)"
    },
    summary: {
      "& div:first-child": {
        order: 3
      },
      "& div:nth-child(2)":{
        order: 1,
        marginRight: "20px",
        ["@media (max-width:1024px)"]: {
          marginRight: "0px"
        }
      },
      ["@media (max-width:1024px)"]: {
        paddingLeft: "0px"
      }
    },
    question: {
      backgroundColor: "rgba(0,0,0,0.0) !important",
      fontFamily: "Circular",
      fontSize: "1.25rem",
      ["@media (max-width:1024px)"]: {
        fontSize: "1rem",
      }
    },
    answer: {
      backgroundColor: "rgba(0,0,0,0.0) !important",
      fontFamily: "Circular",
      fontSize: "1.15em",
      color: "#555555 !important",
      letterSpacing: "0.05em",
      ["@media (max-width:1024px)"]: {
        fontSize: "0.9rem",
      },
      ["@media (max-width:400px)"]: {
        fontSize: "0.66rem"
      }
    },
    closer: {
      background: "none",
      color: "inherit",
      border: "none",
      padding: 0,
      cursor: "pointer",
      "& svg": {
        fontSize: "3.5em"
      }
    }
  })
)

const FaqContainer = styled('div')({
  position: "relative",
  top: 0,
  left: 0,
  zIndex: 999,
  width: "60vw",
  height: "80vh",
  marginTop: "40px",
  overflow: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  '&::-webkit-scrollbar-track': {
    display: "none"
  },
  '&::-webkit-scrollbar-thumb': {
    display: "none"
  },
  ["@media (max-width:1024px)"]: {
    width: "96vw",
    height: "96vh",
    marginTop: "20px",
    marginLeft: "2vw !important",
    paddingLeft: "0 !important"
  },
});

export default function Faq(props:any) {
  const classes = useStyles();
  const close = props.close
  const inlineStyle = (props.inCal) ? {marginLeft: "2vw"} : {marginLeft: "16vw", padding:"60px", paddingTop: 0, marginTop: "-120px"}

  return (
    <FaqContainer style={inlineStyle}>
      <button className={classes.closer} onClick={() => close()}><SvgIcon component={CloseIcon} viewBox="0 0 40 40" /></button>
      <h1 className={classes.faqHeader}>FREQUENTLY ASKED QUESTIONS</h1>
      <Accordion className={`${classes.questionAnswer} ${classes.peachAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.question}>How much does Astrology for Days cost?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            A 1-month subscription is $2.99 USD.<br/>
            A 12-month subscription is $10.99 USD for a year.<br/>
            An 12-month extended subscription (through December of your renewal year) is $20.99.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.peachAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.question}>What do I get in a 12 month subscription to Astrology for Days?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            If you buy a 12 month subscription to Astrology for Days, you get a full 12 months of the calendar, regardless of what month of the year you make the purchase.

            That means that if, for example, you buy Astrology for Days in June of 2021, you have paid for access to the calendar until June 2022!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.lightPinkAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.question}>I have commitment issues; can I get only one month at a time?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            Yes! When you get a one month subscription you have access to all past months, from January 2020 onwards, up to and including the calendar month you have subscribed to.

            When you purchase a one-month subscription, you will have access to the calendar until the same day on which you made the purchase of the following month. As an example, when you purchase a one-month subscription on the 4th of August, 2020, you’ll get to view both August and September of the calendar, until the 4th of September, 2020.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.lightPinkAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.question}>I got the 12 month subscription, but I didn’t get access to 12 months of the calendar, what gives?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            Each new year of transits will be released four months in advance. This means that on September 1st of 2021 you will have access to all of the 2022 months available to you in your subscription.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.yellowAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.question}>Does Astrology for Days work on my Android/iPhone/Tin Can with a string?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            We wanted to make Astrology for Days accessible to all devices! It’s a web-based app, which means that it can be used on any device that connects to the Internet.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.yellowAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.question}>Do you offer refunds?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            We hope that you love Astrology for Days, but all subscription sales are final.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.peachAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.question}>This isn’t written in a language I understand! How can I read it?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            Astrology For Days is a tool for astrology students and professionals, so we use astrological glyphs or symbols.
            Click on the Symbol Index at the very bottom of the page for a handy Symbol Index for all of the glyphs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.peachAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.question}>The transits don’t state what zodiac signs that they are in. How do I know what zodiac sign the planets are in?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            There are two ways to remedy that!
            1. Look to the panel on the left side on desktop, and just below the calendar on mobile. to see when planets ingress into a zodiac sign; these are always written in blue. Just keep track of that, and you’ll always know where the planets are!
            2. Another option is to click on the month (i.e JANUARY, FEBRUARY, etc) and  the ephemeris will appear. You can locate the exact position of all of the planets there.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.yellowAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.question}>Where can I find planetary ingresses (when a planet moves into a new zodiac sign)?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            Planetary ingresses are written in the side panel, and they are always notated in blue.
            Another way to determine what zodiac sign that a planet is in, is by clicking on the Ephemeris, which you can access by tapping on the name of the month at the top of the calendar.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.yellowAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.question}>How Do I Find the Ephemeris?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            Click on the month (i.e JANUARY, FEBRUARY, etc) and the ephemeris will appear. It is calculated for Midnight, in the UTC Time Zone.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.lightPinkAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.question}>How can I be sure that my personal notes stay private?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            We take your privacy very seriously and will never share your information with anyone. The notes that you enter into Astrology for Days are kept safe on an encrypted server.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.lightPinkAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.question}>What's your cancellation policy?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            You can cancel your subscription at any time. Go to ACCOUNT &gt; MANAGE SUBSCRIPTION to cancel or change your subscription. Keep in mind that all sales are final, and we do not issue refunds. You must cancel before your renewal date to prevent being charged for the next subscription period.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.peachAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.question}>What if I want to delete my account?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            Contact customer service and request to have your account deleted off of our servers. This can take up to 60 days.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={`${classes.questionAnswer} ${classes.peachAccordion}`}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.question}>I need help. How can I contact Astrology for Days?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answer}>
            You can contact customer service at any time by emailing astro@lanyadoo.com.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </FaqContainer>
  )
}
